import React, {useEffect} from 'react'
import "../home/home.css"
import Footer from '../../component/footer/footer';
import { Col, Container, Row } from 'react-bootstrap'
import Star from '../../assets/images/star-icon.svg'
import Gimage from '../../assets/images/G.svg'
import Restrictions from '../../assets/images/break-restriction.png'
import Social from '../../assets/images/social-platform.png'
import Security from '../../assets/images/security.png'
import Logo from '../../assets/images/Logo.png'
import ProfetionalTechno from '../../assets/images/professional-technology.png'
import QuickInterface from '../../assets/images/Quick-use-interface.png'
import CustomerService from '../../assets/images/customer-service.png'
import DownlaodApp from '../../component/downloadApp/downlaodApp';


const Home = ({ scrollToDownloadSection }) => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page when the component mounts
      }, []);

  return (
   <>
   <section className="home-section pt-100" id="home">
    <Container className="position-relative">
        <Row className="pt-5">
            <Col lg={7}>
                <div className="hero-content pb-lg-5 pb-4">
                    <div className="hero-text-area text-lg-start text-md-center text-center">
                        <h1 className="hero-heading">Speed<span className="banner-dot">·</span>Simplicity</h1>
                        <h2 className="hero-second-heading">一鍵連線<span className="banner-dot">·</span>快速無限</h2>
                    </div>
                    <a href="#download_app" className="primary-btn mt-5 d-none d-lg-block d-md-none">立即下載</a>
                    
                    <div className="pt-lg-5 pt-3 ms-md-0 ms-4">
                        <p className="header-tile"><img src={Star} alt="star icon" />簡單介面設置</p>
                        <p className="header-tile"><img src={Star} alt="star icon" />提高連接效率</p>
                        <p className="header-tile"><img src={Star} alt="star icon" />一鍵快速使用</p>
                    </div>
                </div>
            </Col>
            <Col lg={5}>
            <img src={Gimage} alt="banner-pic" className="hero-image" />
            </Col>
        </Row>
    </Container>
   </section>
   {/* ================ We focus on your needs Section Start ================ */}
   <section className="bg-00B097 py-100">
    <Container>
        <h2 className="primary-heading text-center text-white pb-100">我們專注於您的需求</h2>
        <Row>
            <Col lg={4}>
                <div className="need-card text-center mb-5">
                    <div className="icon-box p-4">
                        <img className="" src={Restrictions} alt="Break geographical restrictions" />
                    </div>
                    <h3 className="box-card-title mt-4">突破地理限制</h3>
                </div>
            </Col>
            <Col lg={4}>
                <div className="need-card text-center mb-5">
                    <div className="icon-box p-4">
                        <img className="" width="124" src={Social} alt="Social" />
                    </div>
                    <h3 className="box-card-title mt-4">社群平台使用</h3>
                </div>
            </Col>
            <Col lg={4}>
                <div className="need-card text-center">
                    <div className="icon-box p-4">
                        <img className="" width="120" src={Security} alt="security" />
                    </div>
                    <h3 className="box-card-title mt-4">網路隱私安全</h3>
                </div>
            </Col>
        </Row>
    </Container>
   </section>

   {/* ====== Our quality is worthy of your trust! section start =======  */}
   {/* 我們品質值得您信賴！ */}

   <section className="our-quility pt-100 px-100">
    <Container>
        <div className="mb-lg-5 mb-3 text-center">
            <img width="151" src={Logo} alt="" />
            <h2 className="quility-headi mt-3">我們品質值得您信賴</h2>
        </div>
        <Row className="flex-sm-column-reverse flex-column-reverse flex-lg-row align-items-center py-lg-5 py-4">
            
            <Col lg={6}>
                <div className="text-lg-start text-md-center">
                    <div className="text-lg-start text-md-center text-center mt-4 d-lg-inline-block  d-md-inline-block">
                        <h3 className="sub-heading head-line">專業的產品技術</h3>
                        <p className="main-content">突破地理限制，解鎖網路自由</p>
                    </div>
                </div>
            </Col>
            <Col lg={6}>
                <div className="qulity-imgs  text-md-center">
                    <img src={ProfetionalTechno} alt="professional-technology" />
                </div>
            </Col>
            
        </Row>
        <Row className="align-items-center py-lg-5 py-4">
            <Col lg={6}>
                <div className="qulity-imgs right  text-md-center">
                    <img src={QuickInterface} alt="QuickInterface" />
                </div>
            </Col>
            <Col lg={6}>
                <div className="text-lg-end text-md-center text-center">
                    <div className="text-lg-start text-md-center mt-4 d-lg-inline-block d-md-inline-block">
                        <h3 className="sub-heading head-line">快速簡單使用介面</h3>
                        <p className="main-content">我們的應用程式為一鍵開啟<br/>更有效幫助您快速便利使用</p>
                         
                    </div>
                </div>
            </Col>
        </Row>
        <Row className="flex-sm-column-reverse flex-column-reverse flex-lg-row align-items-center py-lg-5 py-4">
            <Col lg={6}>
                <div className="text-lg-start text-md-center">
                <div className="text-lg-start text-md-center text-center mt-4 d-lg-inline-block  d-md-inline-block">
                    <h3 className="sub-heading head-line">完善的客戶服務</h3>
                    <p className="main-content">完善客戶服務，為您解決問題</p>
                </div>
                </div>
            </Col>
            <Col lg={6}>
                <div className="qulity-imgs text-md-center">
                    <img src={CustomerService} alt="Customer Service" />
                </div>
            </Col>
        </Row>
    </Container>
   </section>

   {/*=============== 立即下載部分開始 =================*/}
   <DownlaodApp/>
<Footer/>
   </>
  )
}

export default Home

