import React from 'react'
import Downloadimg from '../../../../assets/images/downloadimg.png';


const HowToDownload = () => {
  return (
    <div> 
      <div className="how-to-download my-5">
        <h2 className="inner-tab-title mb-4">&#9679; &nbsp;如何下載 ExitVPN 應用程式？</h2>
        <h5 className="inner-tab-subtitle ml-30">在您的設備上找到 Play 商店</h5>
        <div className="">
            <img src={Downloadimg} alt="banner-pic" className="mt-3 mb-4" />
        </div>
        <h5 className="inner-tab-subtitle mt-3 ml-30">點擊 Play 商店搜尋 <span className="bold-w-line">ExitVPN</span>， 或者前往我們官網的 <span className="bold-w-line">立即下載</span> 查看。</h5>
    </div>
    </div>
  )
}

export default HowToDownload
