import React, { useState, useEffect } from 'react'
import { Accordion, Col, Nav, Row, Tab } from 'react-bootstrap'
import styled, { keyframes } from 'styled-components';
import '../help/help.css'
import Footer from '../../component/footer/footer';
import HowToDownload from './FaqComponents/Android/HowToDownload';
import HowToUse from './FaqComponents/Android/HowToUse';
import HowToSignup from './FaqComponents/Android/HowToSignup';
import HowToReport from './FaqComponents/Android/HowToReport';
import HowToDelete from './FaqComponents/Android/HowToDelete';
import AppleIcon from '../../assets/images/apple.svg';
import AndroidOS from '../../assets/images/android-os.svg';
import MacOs from '../../assets/images/MacOS_logo.svg';
import WindowOs from '../../assets/images/ri_windows-fill.svg';
import SearchFaq from './FaqComponents/SearchFaq';
import BreadcrumbFile from '../../component/breadcrumb/breadcrumb'
import CustomBreadcrumb from '../../component/breadcrumb/CustomBreadcrumb'
import DownlaodApp from '../../component/downloadApp/downlaodApp';
import { Link } from 'react-router-dom';

const Help = () => {

    const [androidOption, setAndroidOption] = useState(null);
    const [isAccordionVisible, setIsAccordionVisible] = useState(true);

    const handleSelect = (option, name) => {
        setAndroidOption(option);
        setIsAccordionVisible(false);

        setBreadcrumb(mainActiveTab);

        if (name) {
            setActiveBreadcrumb(name); // Set the selected name as the active breadcrumb
            localStorage.setItem('breadcrumb', mainActiveTab + ' > ' + name); // Save breadcrumb to localStorage
          } else {
            setActiveBreadcrumb(''); // Clear the active breadcrumb if no name is provided
            localStorage.removeItem('breadcrumb'); // Remove breadcrumb from localStorage
          }
    };

    const [breadcrumb, setBreadcrumb] = useState('Android');
    const [activeBreadcrumb, setActiveBreadcrumb] = useState('如何下載 ExitVPN 應用程式？');
    const [mainActiveTab, setMainActiveTab] = useState('android');
    // const [subActiveTab, setSubActiveTab] = useState('how-to-download');
   
      const clearBreadcrumb = () => {
        // Reset the selected tab to "Android"
        setMainActiveTab('Android');
        // Clear the breadcrumb from localStorage
        localStorage.removeItem('breadcrumb');
      };

    // Load breadcrumb state from localStorage on component mount
    useEffect(() => {
        setBreadcrumb('FAQ');
        setActiveBreadcrumb(''); 
        const savedBreadcrumb = localStorage.getItem('breadcrumb');
        if (savedBreadcrumb) {
        setBreadcrumb(savedBreadcrumb);
        }
    }, []);

    // Save breadcrumb state to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem('breadcrumb', breadcrumb);
    }, [breadcrumb]);

    const onTabChange = (tabValue, childTabValue) => {
        setBreadcrumb(tabValue);
        setActiveBreadcrumb(childTabValue);
        setMainActiveTab(tabValue);
    };
    
    const growBorder = keyframes`
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
    `;

    const CsFirstNavLink = styled(Nav.Link)`
    &&& {
        color: #000000;
        background-color: #0000;
        border-radius: 0px;
        margin: 18px 6px;
        font-size: 23px;
        font-weight: 400;
        padding: 3px 32px;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            // border-bottom: 3px solid #00B097;
            width: 0;
            bottom: 0;
            left: 0;
            height: 3px;
            
            animation: ${growBorder} 0.3s ease;
            }
    
        &.active {
        //   background-color: #ffffff;
        //   border-bottom: 3px solid #00B097;

        &:before {
            width: 100%;
            background-color: #00B097;
            }
        }
        }
        @media (max-width: 1024px) and (min-width: 768px) {
        &&& {
            font-size: 18px;
            margin: 18px 2px;
            padding: 18px;
        }
        }
        @media (max-width: 768px) {
        &&& {

        }
    }
    `;

    const CsSecondNavLink = styled(Nav.Link)`
    &&& {
        color: #000000;
        background-color: #0000;
        border-radius: 0px;
        padding: 18px 30px 18px 80px;
        width: 100%;
        font-size: 23px;
        font-weight: 400;
    
        &.active,
        &.show > .nav-link {
            background-color: #00B097;
            border-bottom: 0;
            color: #ffffff
        }
        }
        @media (max-width: 1024px) {
        &&& {
            padding: 18px 24px;
            font-size: 18px;
        }
        }
    `;

  return (
    <>
    <div className="help-page">
        <SearchFaq/>
        {/* tab section start */}
        <div className="mt-4 d-md-none">
            <CustomBreadcrumb breadcrumb={breadcrumb} activeBreadcrumb={activeBreadcrumb} mainActiveTab={mainActiveTab} setMainActiveTab={setMainActiveTab} clearBreadcrumb={clearBreadcrumb}/>
        </div>


        <div className="help-main-tabs d-none d-md-block">
            <Tab.Container id="left-tabs-example" defaultActiveKey="android">
                <Row>
                    <Col sm={5}>
                        <div className="pl-80 mt-4">
                            <BreadcrumbFile breadcrumb={breadcrumb} activeBreadcrumb={activeBreadcrumb} mainActiveTab={mainActiveTab} setMainActiveTab={setMainActiveTab} clearBreadcrumb={clearBreadcrumb}/>

                            
                            
                        </div>
                    </Col>
                    <Col sm={7}>
                        <Nav variant="pills" className="">
                            
                                <CsFirstNavLink eventKey="android" onClick={() => onTabChange('Android', '')}>Android</CsFirstNavLink>
                            
                                <CsFirstNavLink eventKey="ios" onClick={() => onTabChange('iOS', '')}>iOS</CsFirstNavLink>
                            
                                <CsFirstNavLink eventKey="windows" onClick={() => onTabChange('Windows', '')}>Windows</CsFirstNavLink>
                            
                                <CsFirstNavLink eventKey="macos" onClick={() => onTabChange('MacOS', '')}>MacOS</CsFirstNavLink>
                            
                        </Nav>
                    </Col>
                    <Col sm={12}>
                        <Tab.Content className="mt-5">
                            {/* Android Tab  */}
                            <Tab.Pane eventKey="android">
                                <Tab.Container id="left-tabs-example" defaultActiveKey="how-to-download">
                                    <Row>
                                        <Col lg={4} md={5}>
                                            <Nav variant="pills" className="" >
                                                
                                                    <CsSecondNavLink eventKey="how-to-download" onClick={() => onTabChange('Android', '如何下載 ExitVPN 應用程式？')}>&#9679; &nbsp;如何下載 ExitVPN 應用程式 ？</CsSecondNavLink>
                                                
                                                
                                                    <CsSecondNavLink eventKey="how-to-use" onClick={() => onTabChange('Android', '如何使用 ExitVPN ？')}>&#9679; &nbsp;如何使用 ExitVPN ？</CsSecondNavLink>
                                                
                                                
                                                    <CsSecondNavLink eventKey="how-to-register" onClick={() => onTabChange('Android', '如何註冊 ExitVPN ?')}>&#9679; &nbsp;如何註冊 ExitVPN ?</CsSecondNavLink>
                                                
                                                
                                                    <CsSecondNavLink eventKey="how-to-report" onClick={() => onTabChange('Android', '如何回報問題 ？')}>&#9679; &nbsp;如何回報問題 ？</CsSecondNavLink>

                                                    {/* <CsSecondNavLink eventKey="how-to-delete" onClick={() => onTabChange('Android', '如何刪除我的帳號？')}>&#9679; &nbsp;如何刪除我的帳號？</CsSecondNavLink> */}

                                                    <CsSecondNavLink href="/如何刪除我的帳戶">&#9679; &nbsp;如何刪除我的帳號？</CsSecondNavLink> 
                                                
                                            </Nav>  

                                        </Col>
                                        <Col className="d-lg-block d-md-none d-none" lg={1}></Col>
                                        <Col lg={7} md={7}>
                                            <Tab.Content>
                                                {/* Android Tab  */}
                                                <Tab.Pane eventKey="how-to-download">
                                                    <HowToDownload/>
                                                    
                                                </Tab.Pane>
                                                {/* iOS Tab  */}
                                                <Tab.Pane eventKey="how-to-use">
                                                    <HowToUse/>
                                                </Tab.Pane>
                                                {/* Window tab  */}
                                                <Tab.Pane eventKey="how-to-register">
                                                    <HowToSignup/>
                                                </Tab.Pane>
                                                {/* MacOS Tab  */}
                                                <Tab.Pane eventKey="how-to-report">
                                                    <HowToReport/>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="how-to-delete">
                                                    <HowToDelete/>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </Tab.Pane>
                            {/* iOS Tab  */}
                            <Tab.Pane eventKey="ios">Second tab content</Tab.Pane>
                            {/* Window tab  */}
                            <Tab.Pane eventKey="windows">Second tab content</Tab.Pane>
                            {/* MacOS Tab  */}
                            <Tab.Pane eventKey="macos">Second tab content</Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </div>

        {/* Help QA start for mobile  */}

        <div className="d-block d-md-none">
            {isAccordionVisible && (
                <Accordion className="cs-accordion" defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header onClick={() => onTabChange('Android', '')}>
                            <div className="accordion_header d-flex align-items-center">
                                <img src={AndroidOS} alt="AndroidOS" />
                                <h4>Android</h4>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body className="p-0">
                            <ul className="android-qa-list p-0 m-0">
                                <li onClick={() => handleSelect(1, '如何下載 ExitVPN 應用程式？')}> 如何下載 ExitVPN 應用程式</li>
                                <li onClick={() => handleSelect(2, '如何使用 ExitVPN？')}>如何使用 ExitVPN</li>
                                <li onClick={() => handleSelect(3, '如何註冊 ExitVPN ?')}>如何註冊 ExitVPN </li>
                                <li onClick={() => handleSelect(4, '如何回報問題？')}>如何回報問題</li>
                                <li> <Link to="/如何刪除我的帳戶" onClick={() => handleSelect(5)}>如何刪除我的帳號</Link></li>
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header onClick={() => onTabChange('iOS', '')}>
                            <div className="accordion_header d-flex align-items-center">
                                <img src={AppleIcon} alt="AndroidOS" />
                                <h4>ios</h4>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                        Lorem ipsum dolor sit amet,
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header onClick={() => onTabChange('Windows', '')}>
                            <div className="accordion_header d-flex align-items-center">
                                <img src={WindowOs} alt="AndroidOS" />
                                <h4>Windows</h4>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                        Lorem ipsum dolor sit amet,
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header onClick={() => onTabChange('MacOS', '')}>
                            <div className="accordion_header d-flex align-items-center">
                                <img src={MacOs} alt="AndroidOS" />
                                <h4>MacOS</h4>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                        Lorem ipsum dolor sit amet,
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            )}
          

            <div className='text-center'>
            {androidOption === 1 && <div><HowToDownload/></div>}
            {androidOption === 2 && <div><HowToUse/></div>}
            {androidOption === 3 && <div><HowToSignup/></div>}
            {androidOption === 4 && <div><HowToReport/></div>}
            {/* {androidOption === 5 && <div><HowToDeleteMyAccountCN/></div>} */}
            </div>
        </div>
        <DownlaodApp/>
        {/* Footer component start */}
        <Footer/>
    </div>
    </>
  )
}

export default Help
