import React, { useEffect } from 'react'
import '../windowsDownload/windowsDownload.css'
import { Col, Row, Container } from 'react-bootstrap'
import WindowDownload from '../../assets/images/windows-download.png'
// import Union from '../../assets/images/mob-union.svg'
import { AiFillAndroid, AiFillApple, AiFillWindows } from "react-icons/ai";
import MacOS from '../../assets/images/MacOS.png'
import { Link } from 'react-router-dom'
import Footer from '../../component/footer/footer';

const WindowsDownload = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page when the component mounts
      }, []);
    
  return (
    <>
    <div className="window-wrap">
    
            <Container fluid>
                <Row className="flex-sm-column-reverse flex-column-reverse flex-lg-row"> 
                    <Col className="px-0" lg={6}>
                        <div className="window-title pt-100 pb-lg-5 pb-4 pb-0 text-center text-lg-start text-md-center">
                            <h1 className="window-heading pt-lg-5 pt-md-0">下載Windows版ExitVPN</h1>
                            <p className="window-para my-lg-5 my-md-5 my-3">
                                ExitVPN 是一款輕鬆享受無限制瀏覽網站內容的應用程式， 簡單且快速，只需ㄧ個點擊，就能立即啟動使用。
                            </p>
                            <p className='version-app'>Version 0.2</p>
                            <a href="https://www.fileduffel.com/exitvpn/exitvpn-win-current.exe" target='blank'><button className="primary-btn">立即下載</button></a>
                        </div>
                        
                        <div className="os-list py-lg-5 py-md-0 py-0" id="download_app">
                            <div className="d-md-block d-lg-none">
                                    <p className="and-text">我們即將推出iOS，MacOS及Windows！</p>
                            </div>
                            <Row className="and-icon text-center justify-content-center">
                        
                                <Col sm={6} md={3}>
                                    <div className="set-icon mb-md-0 mb-5">
                                        <Link to="/android-download">
                                        <AiFillAndroid className="os-icon"/>
                                        <p className="os-title mb-0">Android</p>
                                        </Link>
                                    </div>
                                </Col>
                                <Col sm={6} md={3}>
                                    <div className="set-icon-d mb-md-0 mb-5">
                                        <AiFillApple className="os-icon disable"/>
                                        <p className="os-title disable mb-0">iOS</p>
                                    </div>
                                </Col>
                                <Col sm={6} md={3}>
                                    <div className="set-icon">
                                        <Link to="/windows-download">
                                            <AiFillWindows className="os-icon"/>
                                            <p className="os-title mb-0">Windows</p>
                                        </Link>
                                    </div>
                                </Col>
                                <Col sm={6} md={3}>
                                    <div className="set-icon-d">
                                        <img src={MacOS} alt=""  className="mac-icon" />
                                        <p className="os-title disable mb-0">MacOS</p>
                                    </div>
                                </Col>
                
                            </Row>
                            <div className="d-md-none d-none d-lg-block ">
                                    <p className="and-text">我們即將推出iOS，MacOS及Windows！</p>
                            </div>
                        </div>
                    </Col>
                    <Col className="px-0" lg={6}>
                        <div className="windowphone-bg">
                            <img className="window-img" src={WindowDownload} alt="" />
                        </div>
                    </Col>
                </Row>
            </Container>
    </div>
    <div className="and-footer">
        <Footer/>
    </div>
    </>
  )
}

export default WindowsDownload





