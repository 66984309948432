import React from 'react'
import { AiFillAndroid, AiFillApple, AiFillWindows } from "react-icons/ai";
import MacOS from '../../assets/images/MacOS.png'
import { Link } from 'react-router-dom'
import { Col, Container, Row } from 'react-bootstrap'


const DownlaodApp = () => {


  return (
    <>

   <section className="download-now pt-100" id="download_app">
        <div className="text-center">
            <h2 className="download-heading pb-2">立即下載</h2>
            <h3 className="comming-soon-title pb-4">我們即將推出iOS，MacOS及Windows！</h3>
        </div>
        <div className="os-list bg-CBE1D7 py-5">
            <Container>
                <Row className="justify-content-center text-center">
                    <Col sm={6} md={3}>
                        <div className="set-icon mb-md-0 mb-5">
                            <Link to="/android-download">
                            <AiFillAndroid className="os-icon"/>
                            <p className="os-title mb-0">Android</p>
                            </Link>
                        </div>
                    </Col>
                    <Col sm={6} md={3}>
                        <div className="set-icon-d mb-md-0 mb-5">
                            <AiFillApple className="os-icon disable"/>
                            <p className="os-title disable mb-0">iOS</p>
                        </div>
                    </Col>
                    <Col sm={6} md={3}>
                        <div className="set-icon">
                            <Link to="/windows-download">
                                <AiFillWindows className="os-icon"/>
                                <p className="os-title mb-0">Windows</p>
                            </Link>
                        </div>
                    </Col>
                    <Col sm={6} md={3}>
                        <div className="set-icon-d">
                            <img src={MacOS} alt=""  className="mac-icon" />
                            <p className="os-title disable mb-0">MacOS</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
   </section>
    </>
  )
}

export default DownlaodApp
