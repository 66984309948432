import React, {useState} from 'react'
import { VscGlobe } from 'react-icons/vsc'
import { Link } from 'react-router-dom'
import enTranslation from './translation/cn.json';
import cnTranslation from './translation/en.json';
// import Downloadimg from '../../../../assets/images/downloadimg.png';


const HowToDelete = () => {
  const [currentLanguage] = useState('cn');
    const translations = currentLanguage === 'cn' ? enTranslation : cnTranslation;
  
    // const switchLanguage = () => {
    //   setCurrentLanguage(currentLanguage === 'cn' ? 'en' : 'cn');
    // };
  return (
    <div>
      <div className="how-to-download my-5">
        <div className="d-flex align-items-center">
          <h2 className="inner-tab-title mb-4">&#9679; &nbsp;{translations.heading}</h2>
            <Link className='ps-5'>
              <p className="d-flex align-items-center language-btn mb-4"><VscGlobe/><span className="m-0 ps-2">{translations.buttonLabel}</span></p>
            </Link>
        </div>
      </div>
    </div>
  )
}

export default HowToDelete
