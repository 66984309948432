import React from 'react'
import HowToUseimg from '../../../../assets/images/h2use1.png';
import Report1 from '../../../../assets/images/report1.png';
import Report2 from '../../../../assets/images/report2.png';
import Report3 from '../../../../assets/images/report3.png';

const HowToReport = () => {
  return (
    <div>
        <div className="register my-5">
            <h2 className="inner-tab-title mb-4">&#9679; &nbsp;如何回報問題？</h2>
            <h5 className="inner-tab-subtitle ml-30">於您的設備桌面打開 <span className="bold-w-line">ExitVPN</span> 應用程式。</h5>
            <div className="">
                <img src={HowToUseimg} alt="banner-pic" className="mt-3 mb-4" />
            </div>
            <h5 className="inner-tab-subtitle mt-3 ml-30">於主頁面點擊左上方紅色箭頭這個按鈕。</h5>
            <div className="">
                <img src={Report1} alt="banner-pic" className="mt-3 mb-4 ml-30" />
            </div>
            <h5 className="inner-tab-subtitle ml-30">點擊<span className="bold-w-line">意見回報</span>。</h5>
            <div className="">
                <img src={Report2} alt="banner-pic" className="mt-3 mb-4 ml-30" />
            </div>
            <h5 className="inner-tab-subtitle mt-3 ml-30">於問題類別選擇您想了解的問題，填寫電子信箱、說明後按送出即可。<br/>
            我們收到回報會盡快回覆您。</h5>
            <div className="">
                <img src={Report3} alt="banner-pic" className="mt-3 mb-4 ml-30" />
            </div>
        </div>
    </div>
  )
}

export default HowToReport
