import React from 'react'
import HowToUseimg from '../../../../assets/images/h2use1.png';
import HowToUse2 from '../../../../assets/images/h2use2.png';
import HowToUse3 from '../../../../assets/images/h2use3.png';
import HowToUse4 from '../../../../assets/images/h2use4.png';

const HowToUse = () => {
  return (
    <div>
        <div className="how-to-use my-5">
            <h2 className="inner-tab-title mb-4">&#9679; &nbsp;如何使用 ExitVPN 應用程式？</h2>
            <h5 className="inner-tab-subtitle ml-30">下載安裝完成後，於您的設備桌面打開 <span className="bold-w-line">ExitVPN</span> 應用程式。</h5>
            <div className="">
                <img src={HowToUseimg} alt="banner-pic" className="mt-3 mb-4" />
            </div>
            <h5 className="inner-tab-subtitle mt-3 ml-30">登入您所註冊的 <span className="bold-w-line">電子信箱</span>與<span className="bold-w-line">密碼,</span> 點擊 <span className="bold-w-line">登入</span>。</h5>
            <div className="">
                <img src={HowToUse2} alt="banner-pic" className="mt-3 mb-4 ml-30" />
            </div>
            <h5 className="inner-tab-subtitle ml-30">登入之後，您將看到 ExitVPN 畫面中心有一個按鈕。</h5>
            <div className="">
                <img src={HowToUse3} alt="banner-pic" className="mt-3 mb-4 ml-30" />
            </div>
            <h5 className="inner-tab-subtitle ml-30">點擊它，您可立即連線開始使用。</h5>
            <div className="">
                <img src={HowToUse4} alt="banner-pic" className="mt-3 mb-4 ml-30" />
            </div>
        </div>
    </div>
  )
}

export default HowToUse
