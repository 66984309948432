import React, { useEffect } from 'react'
import '../androidDownload/androidDownload.css'
import { Col, Row, Container } from 'react-bootstrap'
import galaxyNote from '../../assets/images/Galaxy-Note.png'
import gplay from '../../assets/images/logos_google-play-icon.svg'
import { AiFillAndroid, AiFillApple, AiFillWindows } from "react-icons/ai";
import MacOS from '../../assets/images/MacOS.png'
import { Link } from 'react-router-dom'
import Footer from '../../component/footer/footer';

const AndroidDownload = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page when the component mounts
      }, []);
    
  return (
    <>
    <div className="android-wrap">
    
            <Container fluid>
                <Row className="flex-sm-column-reverse flex-column-reverse flex-lg-row"> 
                    <Col className="px-0" lg={6}>
                        <div className="android-title pt-100 pb-lg-5 pb-4 pb-0 text-center text-lg-start text-md-center">
                            <h1 className="android-heading pt-lg-5 pt-md-0">下載Android版ExitVPN</h1>
                            <p className="android-para my-lg-5 my-md-5 my-3">
                                ExitVPN 是一款輕鬆享受無限制瀏覽網站內容的應用程式，<br/>簡單且快速，只需ㄧ個點擊，就能立即啟動使用。
                            </p>
                            <p className='version-app'>Version 0.4.2</p>
                            <div className='d-flex justify-content-center justify-content-lg-start'>
                            <a className="me-md-4 me-2" href="https://play.google.com/store/apps/details?id=com.exitvpn" target='blank'>
                                <button className="primary-btn"> 
                                    <span className='d-flex align-items-center justify-content-center'>
                                    <img src={gplay} alt="" /> 立即下載</span>
                                </button>
                            </a>
                            <a href="https://www.fileduffel.com/exitvpn/exitvpn-android-current.apk" target='blank'><button className="primary-btn">下載apk</button></a>
                            </div>
                        </div>
                        
                        <div className="os-list py-lg-5 py-md-0 py-0" id="download_app">
                            <div className="d-md-block d-lg-none">
                                <p className="and-text">我們即將推出iOS，MacOS及Windows！</p>
                            </div>
                            <Row className="and-icon text-center justify-content-center">
                        
                                <Col sm={6} md={3}>
                                    <div className="set-icon mb-md-0 mb-5">
                                        <Link to="/android-download">
                                        <AiFillAndroid className="os-icon"/>
                                        <p className="os-title mb-0">Android</p>
                                        </Link>
                                    </div>
                                </Col>
                                <Col sm={6} md={3}>
                                    <div className="set-icon-d mb-md-0 mb-5">
                                        <AiFillApple className="os-icon disable"/>
                                        <p className="os-title disable mb-0">iOS</p>
                                    </div>
                                </Col>
                                <Col sm={6} md={3}>
                                    <div className="set-icon">
                                        <Link to="/windows-download">
                                        <AiFillWindows className="os-icon"/>
                                        <p className="os-title mb-0">Windows</p>
                                        </Link>
                                    </div>
                                </Col>
                                <Col sm={6} md={3}>
                                    <div className="set-icon-d">
                                        <img src={MacOS} alt=""  className="mac-icon" />
                                        <p className="os-title disable mb-0">MacOS</p>
                                    </div>
                                </Col>
                
                            </Row>
                            <div className="d-md-none d-none d-lg-block ">
                                    <p className="and-text">我們即將推出iOS，MacOS及Windows！</p>
                            </div>
                        </div>
                    </Col>
                    <Col className="px-0" lg={6}>
                        <div className="galaxyphone-bg">
                            <img className="galaxy-img" src={galaxyNote} alt="" />
                        </div>
                    </Col>
                </Row>
            </Container>
    </div>
    <div className="and-footer">
        <Footer/>
    </div>
    </>
  )
}

export default AndroidDownload





