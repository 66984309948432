import React from 'react'
import HowToUseimg from '../../../../assets/images/h2use1.png';
import Register1 from '../../../../assets/images/register1.png';
import Register2 from '../../../../assets/images/register2.png';

const HowToSignup = () => {
  return (
    <div>
        <div className="register my-5">
            <h2 className="inner-tab-title mb-4">&#9679; &nbsp;如何註冊 ExitVPN？</h2>
            <h5 className="inner-tab-subtitle ml-30">下載安裝完成後，於您的設備桌面打開 <span className="bold-w-line">ExitVPN</span> 應用程式。</h5>
            <div className="">
                <img src={HowToUseimg} alt="banner-pic" className="mt-3 mb-4" />
            </div>
            <h5 className="inner-tab-subtitle mt-3 ml-30">點選畫面上右上方的<span className="bold-w-line">註冊</span>。</h5>
            <div className="">
                <img src={Register1} alt="banner-pic" className="mt-3 mb-4 ml-30" />
            </div>
            <h5 className="inner-tab-subtitle ml-30">輸入您預註冊的電子信箱與密碼，然後點下方<span className="bold-w-line">註冊</span>即可。</h5>
            <div className="">
                <img src={Register2} alt="banner-pic" className="mt-3 mb-4 ml-30" />
            </div>
        </div>
    </div>
  )
}

export default HowToSignup
