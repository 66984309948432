import React from 'react'
import Footer from '../../../component/footer/footer'
import DownlaodApp from '../../../component/downloadApp/downlaodApp'
import '../help.css'
import { Col, Container, Row } from 'react-bootstrap'
import { VscGlobe } from 'react-icons/vsc'
import { Link } from 'react-router-dom'

const HowToDeleteMyAccountCN = () => {
 
  return (
    <>
      <div className="search mt-6">
            <Container>
                <Row>
                    <Col md={2}></Col>
                    <Col md={8}>
                        <div className="px-md-0 px-5">
                            <h1 className="search-heading">如何刪除我的帳戶？</h1>
                        </div>
                    </Col>
                    <Col md={2}></Col>
                </Row>
            </Container>
        </div>
      <div>
            <Container fluid>
                <div className="d-flex justify-content-end pt-3 px-4">
                    <Link to="/how-to-delete-my-account">
                        <p className="d-flex align-items-center language-btn"><VscGlobe/><span className="m-0 ps-2">English</span></p>
                    </Link>
                </div>
                <div className="how-delete-account-page pb-100 pt-3 px-100">
                    <h1 className="delete-account-title text-center pb-lg-5 pb-3">如何請求刪除 ExitVPN 帳戶</h1>
                    <div className="delete-account-para">
                        <p>
                          如果您希望刪除您的ExitVPN帳戶以及所有相關信息，請按照以下說明操作：
                        </p>
                        <p>
                          1. 發起請求：<br/>
                          發送一封郵件至 <Link to="mailto:delete@exitvpn.com" className='delete_link'>delete@exitvpn.com</Link>。 在郵件正文中，請提供您的用戶名或與您的ExitVPN帳戶相關的電子郵件地址。
                        </p>
                        <p>
                          2. 驗證您的身份：<br/>
                          在我們收到您的郵件後，我們將向您的註冊的電子郵件地址發送一封驗證郵件。這是確保您的帳戶安全並確認刪除請求真實性的關鍵步驟。
                        </p>
                        <p>
                          3. 確認刪除：<br/>

                            打開由ExitVPN發送的驗證郵件。按照郵件中提供的說明繼續刪除您的帳戶。請注意，一旦您的帳戶被刪除，與之相關的所有數據將被永久從我們的系統中刪除，無法恢復。 如果您在過程中有任何問題或疑慮，請隨時聯繫我們的支援團隊尋求幫助。
                        </p>
                    </div>
                </div>
            </Container>
            <DownlaodApp/>
            <Footer/>
        </div>
    </>
  )
}

export default HowToDeleteMyAccountCN
