
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, BrowserRouter as Router, Routes  } from 'react-router-dom';
import Home from './pages/home/home';
import Header from './component/header/header';
import AndroidDownload from './pages/androidDownload/androidDownload';
import WindowsDownload from './pages/windowsDownload/windowsDownload';
import FooterEnd from './component/footer/footerEnd';
import About from './pages/about/about';
import AboutCN from './pages/about/aboutCN';
import Policy from './pages/Policy/policy';
import PolicyCN from './pages/Policy/policyCN';
import Help from './pages/help/help';
import HowToDeleteMyAccount from './pages/help/HowToDeleteMyAccount/howToDeleteMyAccount';
import HowToDeleteMyAccountCN from './pages/help/HowToDeleteMyAccount/howToDeleteMyAccountCN';

function App() {
  const isHomePage = window.location.pathname === '/';
  const scrollToDownloadSection = () => {
    if (isHomePage) {
      const section = document.getElementById('download_app');
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      } else {
        window.location.href = '/#download_app';
      }
    } 
  };
  

  return (
    <Router>
      <Header isHomePage={true} scrollToDownloadSection={scrollToDownloadSection} />
      <Routes>
        <Route path="/" element={<Home scrollToDownloadSection={scrollToDownloadSection} />} />
        <Route path="/android-download" element={<AndroidDownload/>} />
        <Route path="/windows-download" element={<WindowsDownload/>} />
        <Route path="/about-us" element={<About/>}/>
        <Route path="/關於我們" element={<AboutCN/>}/>
        <Route path="/privacy-policy" element={<Policy/>}/>
        <Route path="/隱私權政策" element={<PolicyCN/>}/>
        <Route path="/FAQ" element={<Help/>}/>
        <Route path='/how-to-delete-my-account' element={<HowToDeleteMyAccount/>}/>
        <Route path='/如何刪除我的帳戶' element={<HowToDeleteMyAccountCN/>}/>
      </Routes>
      <FooterEnd/>
    </Router>
  );
}

export default App;
