import React from 'react'
import '../footer/footer.css'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'


const FooterEnd = () => {

  return (
    <>
        <div className="footer-end">
          <Container fluid>
            <div className="px-200">
              <div className="footer-inner">
                <p className="copy-right m-0">
                  @ 2023 Exit VPN All Rights Reserved
                </p>
                <div>
                  <Link to="/隱私權政策" className="privacy">隱私政策</Link>
                </div>
              </div>
            </div>
          </Container>
        </div>
    </>
  )
}

export default FooterEnd
