import React from 'react'
import '../footer/footer.css'
import { Col, Container, Row } from 'react-bootstrap'
import Footerpic from '../../assets/images/footer-image.png'
import Facebook from '../../assets/images/facebook.svg'
import Instagram from '../../assets/images/instagram.svg'
import Twitter from '../../assets/images/twitter.svg'
import Youtube from '../../assets/images/youtube.svg'
import Email from '../../assets/images/email.svg'

const Footer = () => {
  return (
    <>
        <div className="footer py-100" id="footer">
          <Container fluid>
            <div className="px-200">
          <h2 className="footer-heading d-md-none d-block text-center pb-3">聯絡我們</h2>

            <Row className="align-items-center">
              <Col md={6}>
                <div className="footer-pic">
                  <img src={Footerpic} alt="Footer pic" />
                </div>
              </Col>
              <Col md={6}>
                <div className="footer-content text-lg-left text-md-left ">
                    <h2 className="footer-heading d-md-block d-none">聯絡我們</h2>
                    <p className="footer-para py-3 pt-5 pt-md-3">我們會於社群平台上提供最新資訊，並且持續更新我們的產品，以便提供最佳的使用體驗，請確保您的APP更新至最新版本。
                    </p>
                    <div className="social-icons">
                      <a href="https://www.facebook.com/myExitVPN" target='blank'><img src={Facebook} alt="facebook" className="social-ic"/></a>
                      <a href="https://www.instagram.com/exitvpn/" target='blank'><img src={Instagram} alt="Instagram" className="social-ic"/></a>
                      <a href="https://twitter.com/ExitVPN_TW" target='blank'><img src={Twitter} alt="Twitter" className="social-ic"/></a>
                      <a href="https://www.youtube.com/@exitvpn/featured" target='blank'><img src={Youtube} alt="Youtube" className="social-ic"/></a>
                      <a href="mailto:" target='blank'><img src={Email} alt="Email" className="social-ic"/></a>
                    </div>
                </div>
              </Col>
            </Row>
            </div>
          </Container>
        </div>
    </>
  )
}

export default Footer
