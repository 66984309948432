import React, {useState} from 'react'
import '../about/about.css'
import { Col, Container, Row } from 'react-bootstrap'
import Logo from '../../assets/images/white-logo.svg'
import { VscGlobe } from "react-icons/vsc";
import Footer from '../../component/footer/footer';
import enTranslation from './translation/cn.json';
import cnTranslation from './translation/en.json';
import AboutBanner from '../../assets/images/about-mob.png'
import DownlaodApp from '../../component/downloadApp/downlaodApp';
import { Link } from 'react-router-dom';

const About = () => {
    const [currentLanguage] = useState('cn');
    const translations = currentLanguage === 'en' ? enTranslation : cnTranslation;
  

  return (
    <>
        <div className="about-us pt-60">
            <div className="about-banner py-mb-3 py-0 px-lg-5 px-0">
                <Container fluid>
                    <Row>
                        <Col lg={5} md={5}>
                        </Col>
                        <Col lg={7} md={7}>
                            <div className="banner-content px-100">
                                <h2 className="d-flex align-items-end justify-content-center">{translations.aboutTitle}<span className="ps-3"><img src={Logo} alt="" /></span></h2>
                                <p className="about-banner-para mb-0">{translations.aboutPara}</p>
                            </div>
                        </Col>
                    </Row>
                    <div className="d-md-block d-none">
                        <div className="d-flex justify-content-end">
                            <Link to="/關於我們">
                            <p className="d-flex align-items-center language-btn"><VscGlobe/><span className="m-0 ps-2">{translations.buttonLabel}</span></p>
                            </Link>
                        </div>
                    </div>
                </Container>
                <div className="mob-about-banner">
                    <div className="d-md-none d-block">
                        <img className="w-100" src={AboutBanner} alt="banner" />
                    </div>
                </div>
                <div className="d-md-none d-block">
                    <div className="ms-4 mt-n4 pb-3">
                        <Link to="/關於我們">
                            <p className="d-flex align-items-center language-btn m-0"><VscGlobe/><span className="m-0 ps-2">{translations.buttonLabel}</span></p>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="our-story-section text-center py-150">
                <div className="container">
                    <div className="our-story pb-3">
                        <h2 className="secondary-heading mb-lg-5 mb-3">{translations.ourStory}</h2>
                        <p className="story-para">
                           {translations.storyPara}
                        </p>
                    </div>
                    <div className="our-story pt-5">
                        <h2 className="secondary-heading mb-lg-5 mb-3">{translations.difference}</h2>
                        <p className="story-para">
                            {translations.differencePara}
                        </p>
                    </div>
                </div>
            </div>
            <div className="join-us">
                <div className="joinus-content">
                    <h2 className="mb-5">{translations.joinUs}</h2>
                    <p className="mb-0">{translations.joinusPara}</p>
                </div>
            </div>
            <Container fluid>
                <div className="contact-us text-center py-100">
                    <h2 className="mb-4">{translations.contactUs}</h2>
                    <p className="m-0 pb-4">{translations.contactPara}</p>
                    <p className="pt-lg-4 pt-0 m-0">{translations.updateDate}</p>
                </div>
            </Container>
            <DownlaodApp/>
            <Footer/>
        </div>
    </>
  )
}

export default About
