import React from 'react'
import { Breadcrumb } from 'react-bootstrap'
import '../breadcrumb/breadcrumb.css'

const CustomBreadcrumb = ({ breadcrumb, activeBreadcrumb, clearBreadcrumb }) => {
  const handleBreadcrumbClick = () => {
    clearBreadcrumb();
  };

  return (
    <div>
      <div className="">
        <Breadcrumb className="cs-breadcrumb">
          <Breadcrumb.Item href="/">首頁</Breadcrumb.Item>
          <Breadcrumb.Item href="faq" onClick={handleBreadcrumbClick}>常見問題</Breadcrumb.Item>
          <Breadcrumb.Item href="faq">{breadcrumb}</Breadcrumb.Item>
          {activeBreadcrumb && <Breadcrumb.Item active>{activeBreadcrumb}</Breadcrumb.Item>}
        </Breadcrumb>
      </div>
        
    </div>
  )
}

export default CustomBreadcrumb
