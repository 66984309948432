import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
// import { styled } from 'styled-components';

const SearchFaq = () => {
    // const CustomInputGroupText = styled(InputGroup.Text)`
    // background-color: #fff;
    // border: none;
    // color: #16756799;
    // border-radius: 0;
    //   }
    // `;

    // const CustomFormControl = styled(Form.Control)`
    // &::focus {
    //     color: #16756799;
    //     border-color: #0000;
    //     outline: 0;
    //     box-shadow: none;
        
    // }
    // &::placeholder {
    //     color: #16756799;
    //     font-size: 20px;
    //     font-weight: 700;
    //   }
    //     background-color: #fff;
    //     border: none;
    //     color: #16756799;
    //     font-size: 20px;
    //     font-weight: 700;
    //     border-radius: 0;

    //     @media (max-width: 768px) {
    //         font-size: 12px;
    //         &::placeholder {
    //           font-size: 12px;
    //         }
    //       }
    // `;

   ;

  return (
    <div>
      <div className="search mt-6">
            <Container>
                <Row>
                    <Col md={2}></Col>
                    <Col md={8}>
                        <div className="px-md-0 px-5">
                            <h1 className="search-heading">常見問答</h1>
                            {/* <div className="">
                                <InputGroup className="">
                                    <CustomInputGroupText className="search-icon" id="basic-addon1"><FaSistrix/></CustomInputGroupText>
                                    <CustomFormControl
                                    placeholder="請輸入關鍵字"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    />
                                </InputGroup>
                            </div> */}
                        </div>
                    </Col>
                    <Col md={2}></Col>
                </Row>
            </Container>
        </div>
    </div>
  )
}

export default SearchFaq
