import React from 'react'
import Footer from '../../../component/footer/footer'
import DownlaodApp from '../../../component/downloadApp/downlaodApp'
import '../help.css'
import { Col, Container, Row } from 'react-bootstrap'
import { VscGlobe } from 'react-icons/vsc'
import { Link } from 'react-router-dom'

const HowToDeleteMyAccount = () => {
 
  return (
    <>
      <div className="search mt-6">
            <Container>
                <Row>
                    <Col md={2}></Col>
                    <Col md={8}>
                        <div className="px-md-0 px-5">
                            <h1 className="search-heading">How to delete my account?</h1>
                        </div>
                    </Col>
                    <Col md={2}></Col>
                </Row>
            </Container>
        </div>
      <div>
            <Container fluid>
                <div className="d-flex justify-content-end pt-3 px-4">
                    <Link to="/如何刪除我的帳戶">
                        <p className="d-flex align-items-center language-btn"><VscGlobe/><span className="m-0 ps-2">中文</span></p>
                    </Link>
                </div>
                <div className="how-delete-account-page pb-100 pt-3 px-100">
                    <h1 className="delete-account-title text-center pb-lg-5 pb-3">How to Request Account Deletion for ExitVPN</h1>
                    <div className="delete-account-para">
                        <p>
                            If you wish to delete your ExitVPN account and all associated information, please follow the instructions below:
                        </p>
                        <p>
                            1. **Initiate the Request:** - Compose an email to <Link to="mailto:delete@exitvpn.com" className='delete_link'>delete@exitvpn.com</Link>. - In the body of your email, please provide your username or the email address associated with your ExitVPN account.
                        </p>
                        <p>
                            2. **Verify Your Identity:** - After we receive your email, we will send a verification email to your registered email address. - This is a crucial step to ensure the security of your account and confirm that the deletion request is genuine.
                        </p>
                        <p>
                            3. **Confirm Deletion:** - Open the verification email sent by ExitVPN. - Follow the provided instructions within the email to proceed with the deletion of your account.
                        </p>
                        <p>
                            Please note that once your account is deleted, all data associated with it will be permanently removed from our systems and cannot be recovered.
                            If you have any questions or encounter any issues during the process, please do not hesitate to contact our support team for assistance.
                        </p>
                    </div>
                </div>
            </Container>
            <DownlaodApp/>
            <Footer/>
        </div>
    </>
  )
}

export default HowToDeleteMyAccount
