import React, {useEffect} from 'react'
import '../Policy/policy.css'
import { Container } from 'react-bootstrap';
import DownlaodApp from '../../component/downloadApp/downlaodApp';
import Footer from '../../component/footer/footer';
import { Link } from 'react-router-dom';
import { VscGlobe } from 'react-icons/vsc';

const PolicyCN = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page when the component mounts
      }, []);
  return (
    <>
        <div className="bg-DDEDE6 mt-6">
            <Container fluid>
                <div className="d-flex justify-content-end pt-3 px-4 ">
                    <Link to="/privacy-policy">
                    <p className="d-flex align-items-center language-btn"><VscGlobe/><span className="m-0 ps-2">English</span></p>
                    </Link>
                </div>
                <div className="policy-page pb-100 pt-3 px-100">
                    
                    <h1 className="policy-title text-center pb-lg-5 pb-3">ExitVPN隱私權政策</h1>
                    <p className="policy-para pb-2">在 ExitVPN，您的隱私對我們至關重要。 本隱私政策說明了我們收集的資料類型、我們如何使用這些資料以及我們為保護您的個人資料而採取的措施。使用 ExitVPN 的服務即表示您同意本政策。</p>

                    <div className="pb-3">
                        <h4 className="policy-sub-title m-0">我們收集的資料</h4>
                        <p className="policy-para m-0">1. 用戶電子郵件地址：為了建立和管理您的帳戶，我們收集您的電子郵件地址。 這有助於我們確保您安全使用您的帳戶、提供客戶服務並提供與服務相關的重要更新。</p>
                        <p className="policy-para m-0">2. 基本日誌：我們維護最少的日誌，以確保我們服務的功能、安全性和性能。 這些日誌包括連接時間戳、服務器選擇和頻寬使用等資料。 這些日誌對於解決技術問題、管理伺服器負載和解決安全問題是必需的。</p>
                    </div>

                    <div className="pb-3">
                        <h4 className="policy-sub-title m-0">我們不收集的信息</h4>
                        <p className="policy-para m-0">1. 使用資料：我們不會收集與您的在線活動相關的任何使用資料。 這包括訪問的網站、訪問的內容或在線會話的持續時間。 我們致力於為您提供真正隱私且安全的瀏覽體驗。</p>
                    </div>

                    <div className="pb-3">
                        <h4 className="policy-sub-title m-0">資料的使用</h4>
                        <p className="policy-para m-0">1. 帳戶管理：我們使用您的電子郵件地址來建立和管理您的帳戶，以及發送與您的訂閱和服務相關的重要通知。</p>
                        <p className="policy-para m-0">2. 技術改進：我們收集的基本日誌有助於我們分析和改進我們的服務。 該資料用於監控服務器性能、優化服務器位置以及解決技術問題。</p>
                    </div>

                    <div className="pb-3">
                        <h4 className="policy-sub-title m-0">資料安全</h4>
                        <p className="policy-para m-0">我們致力於保護您的個人資料，並實施了行業標準的安全措施，以防止未經授權的使用、洩漏、更改或破壞您的信息。 我們的系統定期進行審核和更新，以確保最高級別的安全性。</p>
                    </div>

                    <div className="pb-3">
                        <h4 className="policy-sub-title m-0">用戶的責任</h4>
                        <p className="policy-para m-0">ExitVPN 致力於營造一個安全可靠的在線環境。 因此，我們禁止使用我們的服務進行任何非法活動，包括但不限於傳輸您不擁有權利的受版權保護的材料、駭客攻擊或攻擊其他電腦網絡/系統。</p>
                    </div>

                    <div className="pb-3">
                        <h4 className="policy-sub-title m-0">第三方</h4>
                        <p className="policy-para m-0">我們不會出於商業目的出售、交易或以其他方式將您的個人資料轉讓給第三方。 我們可能僅出於向您提供服務的目的而與值得信賴的服務提供商共享有限的信息。 但是，我們確保此類提供商受到保密協議的保護並遵守資料保護法規。</p>
                    </div>

                    <div className="pb-3">
                        <h4 className="policy-sub-title m-0">隱私政策的變更</h4>
                        <p className="policy-para m-0">ExitVPN 保留根據需要更新本隱私政策的權利，以反映我們的服務或法律要求的變化。 我們將通過電子郵件或我們網站上的通知通知您任何重大變更。</p>
                    </div>

                    <div className="pb-3">
                        <h4 className="policy-sub-title m-0">聯繫我們</h4>
                        <p className="policy-para m-0">如果您對您的個人資料或本隱私政策有任何問題、疑慮或請求，請通過 support@exitvpn.com 聯繫我們的支持團隊。</p>
                    </div>

                    <div className="pb-3">
                        <p className="policy-para m-0">最後更新：2023 年 8 月 14 日</p>
                    </div>
                </div>
            </Container>
            <DownlaodApp/>
            <Footer/>
        </div>
    </>
  )
}

export default PolicyCN
