import React, {useEffect} from 'react'
import '../Policy/policy.css'
import { Container } from 'react-bootstrap';
import DownlaodApp from '../../component/downloadApp/downlaodApp';
import Footer from '../../component/footer/footer';
import { VscGlobe } from 'react-icons/vsc';
import { Link } from 'react-router-dom';


const Policy = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page when the component mounts
      }, []);
  return (
    <>
        <div className="bg-DDEDE6 mt-6">
            <Container fluid>
                <div className="d-flex justify-content-end pt-3 px-4">
                    <Link to="/隱私權政策">
                    <p className="d-flex align-items-center language-btn"><VscGlobe/><span className="m-0 ps-2">中文</span></p>
                    </Link>
                </div>
                <div className="policy-page pb-100 pt-3 px-100">
                    
                    <h1 className="policy-title text-center pb-lg-5 pb-3">ExitVPN Privacy Policy</h1>
                    <div className="english-para">
                    <p className="policy-para mb-3">At ExitVPN, your privacy is of paramount importance to us. This Privacy Policy outlines the types of information we collect, how we use it, and the measures we take to safeguard your personal data. By using ExitVPN's services, you consent to the practices described in this policy.</p>
                    <div className="mb-3">
                        <h4 className="policy-sub-title m-0">Information We Collect</h4>
                        <p className="policy-para m-0">1. User Email Address: To create and manage your account, we collect your email address. This helps us ensure secure access to your account, provide customer support, and communicate important service-related updates.</p>
                        <p className="policy-para m-0">2. Basic Logs: We maintain minimal logs to ensure the functionality, security, and performance of our service. These logs include information such as connection timestamps, server choices, and bandwidth usage. These logs are necessary for troubleshooting technical issues, managing server loads, and addressing security concerns.</p>
                    </div>

                    <div className="mb-3">
                        <h4 className="policy-sub-title m-0">Information We Do Not Collect</h4>
                        <p className="policy-para m-0">1. Usage Data: We do not collect any usage data related to your online activities. This includes websites visited, content accessed, or the duration of your online sessions. We believe in providing you with a truly private and secure browsing experience.</p>
                    </div>

                    <div className="mb-3">
                        <h4 className="policy-sub-title m-0">Use of Information</h4>
                        <p className="policy-para m-0">1. Account Management: We use your email address to create and manage your account, as well as to send important notifications related to your subscription and the service.</p>
                        <p className="policy-para m-0">2. Technical Improvements: The basic logs we collect help us to analyze and improve our service. This data is used to monitor server performance, optimize server locations, and troubleshoot technical issues.</p>
                    </div>

                    <div className="mb-3">
                        <h4 className="policy-sub-title m-0">Data Security</h4>
                        <p className="policy-para m-0">We are committed to protecting your personal data and have implemented industry-standard security measures to prevent unauthorized access, disclosure, alteration, or destruction of your information. Our systems are regularly audited and updated to ensure the highest level of security.</p>
                    </div>

                    <div className="mb-3">
                        <h4 className="policy-sub-title m-0">User Responsibilities</h4>
                        <p className="policy-para m-0">ExitVPN is committed to fostering a safe and secure online environment. Therefore, we prohibit any illegal activities using our service, including but not limited to transmitting copyrighted materials that you do not own the rights to, hacking, or attacking other computer networks/systems.</p>
                    </div>

                    <div className="mb-3">
                        <h4 className="policy-sub-title m-0">Third Parties</h4>
                        <p className="policy-para m-0">We do not sell, trade, or otherwise transfer your personal information to third parties for commercial purposes. We may share limited information with trusted service providers solely for the purpose of providing the service to you. However, we ensure that such providers are bound by confidentiality agreements and adhere to data protection regulations.</p>
                    </div>

                    <div className="mb-3">
                        <h4 className="policy-sub-title m-0">Changes to Privacy Policy</h4>
                        <p className="policy-para m-0">ExitVPN reserves the right to update this Privacy Policy as necessary to reflect changes in our services or legal requirements. We will notify you of any significant changes via email or through notices on our website.</p>
                    </div>

                    <div className="mb-3">
                        <h4 className="policy-sub-title m-0">Contact Us</h4>
                        <p className="policy-para m-0">If you have any questions, concerns, or requests regarding your personal data or this Privacy Policy, please contact our support team at support@exitvpn.com.</p>
                    </div>

                    <div className="">
                        <p className="policy-para m-0">Last Updated: August 14, 2023</p>
                    </div>
                    </div>
                </div>
            </Container>
            <DownlaodApp/>
            <Footer/>
        </div>
    </>
  )
}

export default Policy
