import React, {useState} from 'react'
import '../header/header.css'
import { Container, Nav, Navbar, Offcanvas } from 'react-bootstrap'
import LogoSign from '../../assets/images/logo-sign.svg' 
import LogoExitvpn from '../../assets/images/ExitVPN.svg' 
import Toggle from '../../assets/images/toggle.svg'

const Header = () => {


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      
      {['lg'].map((expand) => (
        <Navbar key={expand} expand={expand} className="top-nav bg-white px-3" fixed="top">
          <Container fluid>
            <Navbar.Brand href="/">
              <div className="d-flex align-items-center">
                <img className="logosign me-2" src={LogoSign} alt="site logo" />
                <img className="logovpn d-none d-md-block me-4" src={LogoExitvpn} alt="site logo" />
              </div>
            </Navbar.Brand>
            <div className="d-flex align-items-center">
              <a href="#download_app" className="nav-primary-btn mt-lg-5 mt-md-0 mt-0 me-3 d-lg-none d-md-block d-block">立即下載</a>
              <div className="toggle d-lg-none d-md-block d-block ">
                <img src={Toggle} alt="toggle" onClick={handleShow}/>
              </div>
            </div>
            {/* <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} /> */}
            <Navbar.Offcanvas
              className="side-nav"
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              show={show} onHide={handleClose}
            >
              <Offcanvas.Header closeButton className=''>
              &nbsp;
              </Offcanvas.Header>
              <Offcanvas.Body>
              <Nav className="ms-auto header-nav">
                <Nav.Link className="cs-nav-link" onClick={() => setShow(false)} href="/">首頁</Nav.Link>
                <Nav.Link className="cs-nav-link" onClick={() => setShow(false)} href="關於我們">關於我們</Nav.Link>
                <Nav.Link className="cs-nav-link" onClick={() => setShow(false)} href="#download_app">立即下載</Nav.Link>
                <Nav.Link className="cs-nav-link" onClick={() => setShow(false)} href="faq">常見問答</Nav.Link>
                <Nav.Link className="cs-nav-link me-0" onClick={() => setShow(false)} href="#footer">聯絡我們</Nav.Link>
              </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </div>
  )
}

export default Header
